import {App} from '@capacitor/app'
import {Capacitor} from '@capacitor/core'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

// Adds an app listener which pushes deep links onto the history stack.
const useDeepLinkHandler = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return
    const listener = App.addListener('appUrlOpen', ({url}) => {
      const {hostname, pathname, search} = new URL(url)
      if (hostname === window.location.hostname) {
        navigate(`${pathname}${search}`)
      }
    })
    return () => {
      listener.remove()
    }
  })
}

export default useDeepLinkHandler

import {AppBar, Toolbar, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useEffect, useRef, useState} from 'react'
import {useLocation, useMatch} from 'react-router-dom'
import {useSwipeable} from 'react-swipeable'

import {CloseIcon, MobileNavigationIcon} from './components/icons'
import SidebarMenu from './components/SidebarMenu'
import useIsDesktop from './hooks/useIsDesktop'

const Layout = ({children}) => {
  const styles = useStyles()
  const {pathname} = useLocation()
  const isMainRoute = [
    '/campaigns',
    '/chat_message_templates',
    '/inbox',
    '/merchant_customer_users',
    '/sandbox',
    '/settings',
    '/team-chat',
    useMatch('/inbox/:channelId')?.pathname,
  ].includes(pathname)
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)
  const rootRef = useRef()
  const contentRef = useRef<HTMLDivElement>(null)
  const {
    sidebarRef,
    swipeableProps,
  } = useSwipeOpenMobileSidebar<HTMLDivElement>({
    isMobileSidebarOpen, setIsMobileSidebarOpen,
  })
  // To prevent the existing root ref from getting overridden, we share the ref with the
  // useSwipeOpenMobileSidebar ref through this passthrough
  // https://github.com/FormidableLabs/react-swipeable#how-to-share-ref-from-useswipeable
  const rootRefPassthrough = element => {
    swipeableProps.ref(element)
    rootRef.current = element
  }
  // When navigating between routes the scroll position will be preserved
  // from on page to the next. That's why we reset the scroll position
  // here on every route change event.
  // See also: https://stackoverflow.com/a/61602724/19511937
  useEffect(() => {contentRef.current?.scrollTo(0, 0)}, [pathname])
  return (
    <div
      className={`
        ${styles.root}
        ${isMainRoute ? styles.isMainRoute : ''}
        ${isMobileSidebarOpen ? styles.isMobileSidebarOpen : ''}
      `}
      {...swipeableProps}
      ref={rootRefPassthrough}
    >
      <div className={styles.sidebar} ref={sidebarRef}>
        <SidebarMenu onClose={() => setIsMobileSidebarOpen(false)} />
        <CloseIcon
          className={styles.mobileCloseSidebarButton}
          color="background"
          onClick={() => setIsMobileSidebarOpen(false)}
        />
      </div>
      <main className={styles.main}>
        <AppBar className={styles.mobileAppBar} position="static">
          <Toolbar className={styles.toolbar} variant="dense">
            <MobileNavigationIcon
              className={styles.mobileNavigationIcon}
              color="primary"
              onClick={() => setIsMobileSidebarOpen(true)}
            />
            <Typography
              className={styles.mobileAppBarTitle}
              color="primary"
              id="react-admin-title"
              variant="h3"
            />
          </Toolbar>
        </AppBar>
        <div className={styles.content} ref={contentRef}>
          {/*
            TODO: Add prop validation which requires `children` to be an instance of a
                  custom `Page` component which shall be the parent component for all
                  pages.
          */}
          {children}
        </div>
      </main>
    </div>
  )
}

const useSwipeOpenMobileSidebar = <T extends HTMLElement>({
  isMobileSidebarOpen, setIsMobileSidebarOpen,
}: UseSwipeOpenMobileSidebarProps) => {
  const isDesktop = useIsDesktop()
  const sidebarRef = useRef<T>(null)
  const canSwipeRef = useRef(false)
  const swipeableProps = useSwipeable({
    onSwipeStart: ({dir, initial: [initialX]}) => {
      // The open sidebar swipe is only considered if the user starts swiping from the
      // very beginning(from left to right) of the screen.
      const isOpening = (dir === 'Right') && (initialX <= window.innerWidth * .2)
      // The close swipe is only considered if the user starts swiping from the very
      // end(from right to left) of the screen.
      const isClosing = (dir === 'Left') && (initialX >= window.innerWidth * .8)
      // If the user touches the good side of the screen the swiping can be performed.
      canSwipeRef.current = !isDesktop &&
        ((isOpening && !isMobileSidebarOpen) || (isClosing && isMobileSidebarOpen))
      // Remove the transition effect while dragging the sidebar so that the dragging
      // is smooth.
      sidebarRef.current && (sidebarRef.current.style.transitionProperty = 'none')
    },
    onSwiped: ({deltaX, initial: [initialX]}) => {
      sidebarRef.current && (sidebarRef.current.style.transform = null as any)
      sidebarRef.current && (sidebarRef.current.style.transitionProperty = null as any)
      if (!canSwipeRef.current) return
      const cursorX = initialX + deltaX
      setIsMobileSidebarOpen(cursorX > window.innerWidth * .5)
    },
    onSwiping: ({deltaX, initial: [initialX]}) => {
      if (!canSwipeRef.current) return
      const cursorX = initialX + deltaX
      setIsMobileSidebarOpen(true)
      // Align the sidebar width with the cursor position. This is what
      // creates the left and right dragging experience.
      sidebarRef.current && (
        sidebarRef.current.style.transform =
        `translateX(-${window.innerWidth - cursorX}px)`
      )
    },
  })
  return {sidebarRef, swipeableProps}
}

const useStyles = makeStyles(theme => ({
  content: {
    '& > div:first-child': {
      height: '100%',
    },
    backgroundColor: theme.palette.background.paper,
    flex: '6.625rem 1',
    flexBasis: 0,
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: 0,
    marginLeft: '18vw',
    marginTop: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      overflowX: 'auto',
    },
  },
  isMainRoute: {},
  isMobileSidebarOpen: {},
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 'var(--safe-area-top-inset)',
  },
  mobileAppBar: {
    // TODO: Include extra pixels only on phones with a top notch, see also:
    //       https://css-tricks.com/the-notch-and-css/
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    background: theme.palette.background.paper,
    boxShadow: 'none',
    height: '50px',
    marginBottom: theme.remSpacing(1),
    marginTop: theme.remSpacing(1),
    position: 'relative',
  },
  mobileAppBarTitle: {
    borderLeft: `1px solid ${theme.palette.disabled.main}`,
    flexGrow: 1,
    marginLeft: theme.remSpacing(2),
    paddingLeft: theme.remSpacing(2),
  },
  mobileCloseSidebarButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    cursor: 'pointer',
    position: 'absolute',
    right: theme.remSpacing(2),
    top: `calc(var(--safe-area-top-inset) + ${theme.remSpacing(4.5)})`,
  },
  mobileNavigationIcon: {
    cursor: 'pointer',
    width: '29px',
  },
  root: {
    [theme.breakpoints.down('lg')]: {
      '&:not($isMainRoute) $mobileAppBar': {
        display: 'none',
      },
      '&:not($isMobileSidebarOpen) $sidebar': {
        transform: 'translateX(-100vw)',
      },
      backgroundColor: theme.palette.background.paper,
    },
    '--app-height': 'calc(100vh - var(--notification-prompt-height))',
    display: 'flex',
    flexDirection: 'column',
    height: 'var(--app-height)',
    /* Minimum width to avoid broken layouts based on smallest popular phone iPhone SE */
    minWidth: '375px',
    width: '100vw',
  },
  sidebar: {
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      transform: 'translateX(0)',
      transition: 'all ease 0.3s',
      width: '100vw',
      zIndex: 1001,
    },
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: 'var(--app-height)',
    paddingTop: 'var(--safe-area-top-inset)',
    position: 'fixed',
    width: '18vw',
  },
  toolbar: {
    padding: 0,
  },
}))

interface UseSwipeOpenMobileSidebarProps {
  isMobileSidebarOpen: boolean
  setIsMobileSidebarOpen: (open: boolean) => void
}

export default Layout

import './App.css'

import {ApolloProvider} from '@apollo/client'
import {StatusBar} from '@capacitor/status-bar'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import {setUser as setSentryUser, withSentryReactRouterV6Routing} from '@sentry/react'
import {SafeArea} from 'capacitor-plugin-safe-area'
import {ReactNode, useContext, useEffect, useState} from 'react'
import {
  Admin,
  CustomRoutes as RaCustomRoutes,
  Loading,
  memoryStore,
  Resource,
  useGetIdentity,
} from 'react-admin'
import {BrowserRouter, Navigate, Route} from 'react-router-dom'

import AppFavicon from './components/AppFavicon'
import AppLanguageSetup from './components/AppLanguageSetup'
import AppUpdateAvailableDialog from './components/AppUpdateAvailableDialog'
import Center from './components/Center'
import UnreadChatMessagesBrowserNotificationPresenter
  from './components/chat/UnreadChatMessagesBrowserNotificationPresenter'
import ExtendedNotification from './components/ExtendedNotification'
import PushNotificationsHandler from './components/PushNotificationsHandler'
import ApiUrlsContext, {ApiUrlsProvider} from './contexts/ApiUrlsContext'
import {BrowserNotificationsProvider} from './contexts/BrowserNotificationsContext'
import {HistoryStackProvider} from './contexts/HistoryStackContext'
import {MobileKeyboardProvider} from './contexts/MobileKeyboardContext'
import SandboxProvider from './contexts/SandboxContext'
import useAuthWatcher from './hooks/useAuthWatcher'
import useBaseHostname from './hooks/useBaseHostname'
import useDeepLinkHandler from './hooks/useDeepLinkHandler'
import useGetAppUpdateInfo from './hooks/useGetAppUpdateInfo'
import useHasPermission from './hooks/useHasPermission'
import useMobileAppBadge from './hooks/useMobileAppBadge'
import useSessionMerchantUser from './hooks/useSessionMerchantUser'
import Layout from './Layout'
import CompanyDetailsPage from './pages/CompanyDetailsPage'
import ExternalChatPage from './pages/ExternalChatPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import InternalChatPage from './pages/InternalChatPage/index'
import LoginPage from './pages/LoginPage'
import NotFoundPage from './pages/NotFoundPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import SignupPage from './pages/SignupPage'
import {
  CampaignCreate,
  CampaignShow,
  CampaignsList,
} from './resources/campaignsResource'
import {
  ChatMessageTemplateCreate,
  ChatMessageTemplateShow,
  ChatMessageTemplatesList,
} from './resources/chatMessageTemplatesResource'
import {
  MerchantCustomerUserCreate,
  MerchantCustomerUsersEdit,
  MerchantCustomerUsersList,
} from './resources/merchantCustomerUsersResource'
import theme from './theme'
import authProvider from './utils/authProvider'
import buildDataProvider, {_apolloClient} from './utils/dataProvider'
import {isEmbedded} from './utils/embedded'
import i18nProvider, {DEFAULT_LANGUAGE, MESSAGES} from './utils/i18nProvider'
import {isNativeAndroidApp, isNativeMobileApp} from './utils/platform'
import {setTrackingEnabled, setUser} from './utils/tracking'

const App = () => {
  const baseHostname = useBaseHostname()
  const {isAppUpdateAvailable, isImmediateUpdateAllowed} = useGetAppUpdateInfo()
  useEffect(() => {
    if (!isNativeMobileApp) return
    (async () => {
      if (isNativeAndroidApp) {
        await StatusBar.setOverlaysWebView({overlay: true})
      }
      const {insets: {top}} = await SafeArea.getSafeAreaInsets()
      document.documentElement.style.setProperty('--safe-area-top-inset', `${top}px`)
    })()
  })
  return (
    <ApiUrlsProvider baseHostname={baseHostname}>
      <BrowserRouter>
        <HistoryStackProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              {(isNativeMobileApp && isAppUpdateAvailable) ? (
                <AppUpdateAvailableDialog
                  isImmediateUpdateAllowed={isImmediateUpdateAllowed}
                />
              ) : (
                <AppResources />
              )}
            </ThemeProvider>
          </StyledEngineProvider>
        </HistoryStackProvider>
      </BrowserRouter>
    </ApiUrlsProvider>
  )
}

const AppResources = () => {
  const [dataProvider, setDataProvider] = useState<any>(null)
  const {graphqlApiUrl, graphqlWebsocketApiUrl} = useContext(ApiUrlsContext)
  useDeepLinkHandler()
  useEffect(() => {
    const _buildDataProvider = async () => {
      await authProvider.checkAuth({}).catch(() => {})
      const dataProvider = await buildDataProvider(
        {
          getToken: () => authProvider.getToken(),
          uri: graphqlApiUrl,
          websocketUri: graphqlWebsocketApiUrl,
        },
      )
      setDataProvider(() => dataProvider)
    }
    _buildDataProvider()
  }, [graphqlApiUrl, graphqlWebsocketApiUrl])
  if (!dataProvider) {
    return (
      <Center>
        <Loading
          loadingPrimary={MESSAGES[DEFAULT_LANGUAGE].ra.page.loading}
          // @ts-ignore
          loadingSecondary={null}
        />
      </Center>
    )
  }
  return (
    <ApolloProvider client={_apolloClient}>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        catchAll={NotFoundPage}
        i18nProvider={i18nProvider}
        loginPage={LoginPage}
        layout={Content}
        store={memoryStore()}
        notification={ExtendedNotification}
        theme={theme}
      >
        <Resource name="aggregated_merchant_customer_user_tags" />
        <Resource
          create={withPermissionsCheck(CampaignCreate, 'create', 'campaigns')}
          list={CampaignsList}
          name="campaigns"
          show={CampaignShow}
        />
        <Resource name="channels" />
        <Resource name="channel_chat_bot_users" />
        <Resource name="channel_merchant_users" />
        <Resource name="channel_working_hours" />
        <Resource name="contact_groups" />
        <Resource
          create={withPermissionsCheck(
            ChatMessageTemplateCreate, 'create', 'chat_message_templates'
          )}
          list={ChatMessageTemplatesList}
          name="chat_message_templates"
          show={ChatMessageTemplateShow}
        />
        <Resource name="customer_users" />
        <Resource name="file_mime_types" />
        <Resource
          create={withPermissionsCheck(
            MerchantCustomerUserCreate, 'create', 'merchant_customer_users'
          )}
          edit={MerchantCustomerUsersEdit}
          list={MerchantCustomerUsersList}
          name="merchant_customer_users"
        />
        <Resource name="merchant_customer_user_contact_groups" />
        <Resource name="merchant_customer_user_tags" />
        <Resource name="merchant_user_contact_groups" />
        <Resource name="merchant_users" />
        <Resource name="merchants" />
        <Resource name="organization_merchant_users" />
        <Resource name="secondary_responsible_merchant_users" />
        <Resource name="tags" />
        <Resource name="users" />
        <Resource name="whatsapp_accounts" />
        <Resource name="whatsapp_message_templates" />
        <CustomRoutes>
          <Route key="home" element={<Navigate to="/inbox" />} path="/" />
          <Route element={<ExternalChatPage />} key="inbox" path="/inbox"/>
          <Route
            element={<ExternalChatPage />}
            key="inbox-channel"
            path="/inbox/:channelId"
          />
          <Route
            element={<ExternalChatPage />}
            key="inbox-chat"
            path="/inbox/:channelId/:chatId"
          />
          <Route element={<InternalChatPage />} key="internal-chat" path="/team-chat"/>
          <Route
            element={<InternalChatPage />}
            key="internal-chat"
            path="/team-chat/:chatId"
          />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route element={<SignupPage/>} key="signup" path="/signup" />
          <Route
            element={<ForgotPasswordPage/>}
            key="forgot-password"
            path="/forgot-password"
          />
          <Route
            element={<ResetPasswordPage />}
            key="reset-password"
            path="/reset-password/:passwordResetCode"
          />
          <Route
            element={<CompanyDetailsPage/>}
            key="company-details"
            path="/company-details"
          />
        </CustomRoutes>
      </Admin>
    </ApolloProvider>
  )
}

const Content = ({children}: ContentProps) => {
  useAuthWatcher()
  useMobileAppBadge()
  return (
    <>
      <AppLanguageSetup />
      <AppFavicon />
      <TrackingSetup />
      <PushNotificationsHandler>
        <MobileKeyboardProvider>
          <SandboxProvider>
            <BrowserNotificationsProvider
              disabled={isEmbedded || isNativeMobileApp}
              shouldShowUserPrompt
            >
              {!isNativeMobileApp && <UnreadChatMessagesBrowserNotificationPresenter />}
              <Layout>{children}</Layout>
            </BrowserNotificationsProvider>
          </SandboxProvider>
        </MobileKeyboardProvider>
      </PushNotificationsHandler>
    </>
  )
}

const CustomRoutes = withSentryReactRouterV6Routing(RaCustomRoutes as any)

const withPermissionsCheck = (Component, action, resource) =>
  props => useHasPermission(action, resource) ?
    <Component {...props} /> :
    <NotFoundPage />

const TrackingSetup = () => {
  const {identity: {id: userId, isImpersonatedUser, merchantId} = {}} = useGetIdentity()
  const {merchantUser: {emailAddress} = {}} = useSessionMerchantUser()
  useEffect(() => {
    if (!(userId && emailAddress)) return
    setSentryUser({id: userId as string})
    setTrackingEnabled(!(emailAddress.endsWith('@flinkit.de') || isImpersonatedUser))
    setUser(userId as string, emailAddress)
    return () => {
      setSentryUser({id: undefined})
      setUser(null, null)
      setTrackingEnabled(false)
    }
  }, [emailAddress, isImpersonatedUser, merchantId, userId])
  return null
}

interface ContentProps {
  children?: ReactNode
}

export default App
